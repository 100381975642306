<template>
  <div class="p-10">
    <div class="userinfo anglebox buble-bg">
      <div class="user flex js-sp align-center buble-line">
        <div class="flex js-st align-center" @click="jump('/person')">
          <i class="iconfont quote-key-text mg-rt-md icon-type-04-copy"></i>
          <!-- <img src="@/assets/images/head.png" alt /> -->
          <p class="name buble-t1 text-12 text-left">{{ nickname }}</p>
        </div>
        <i class="el-icon-arrow-right text-14 buble-t1"></i>
      </div>
      <div class="center-model  buble-line">
        <div class="flex js-sp mg-bt-10">
          <ul class="text-left w-72">
            <li class="text-14 buble-t3 mb-8 h_18 block">
              {{ $t("header.wallet") }}
            </li>
            <li
              class="text-12 buble-t1 mb-8 text-hover h_18 break-all"
              @click="jump(item.path)"
              v-for="item in state.person"
              :key="item.name"
              v-show="item.show"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="text-left max-content" v-if="isBibiShow">
            <li class="text-14 buble-t3 mb-8 h_18 block">
              {{ $t("header.commission") }}
            </li>
            <li
              class="text-12 buble-t1 mb-8 text-hover h_18 break-all"
              v-for="item in state.person2"
              :key="item.name"
              @click="jump(item.path)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div
          v-if="isVirtualShow"
          class="radius-50 login-btn share-color text-12 w-100 pd-xs point"
          @click="changeVirtualWallet"
        >
          {{
            virtual_wallet
              ? $t("header.switchRealAccount")
              : $t("header.switchDemoAccount")
          }}
        </div>
      </div>
      <div class="logout text-12" @click="logout()">
        {{ $t("header.signOutOfAccount") }}
      </div>
    </div>
    <Messagebox
      :text="$t('formTip.cashTip')"
      :type="state.safetyType"
      :dialog="state.dialogSafetyTip"
      @onconfirm="confirm"
      @viewSafeStatus="viewSafeStatus"
      @oncancer="onCancel"
    />
    <VerifiedBox
      :text="$t('formTip.certificationTip')"
      :dialog="state.dialogVerfiedTip"
      :type="state.type"
      @onconfirm="onVerfiedConfirm"
      @viewSafeStatus="onVerfiedConfirm"
      @oncancer="onVerfiedCancel"
    />
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Messagebox from "@/components/messageBox";
import VerifiedBox from "@/components/verifiedBox";
import UseSystemSetting from "@/commonapi/useSystemSetting";
export default {
  name: "Personbuble",
  components: { Messagebox, VerifiedBox },
  props: {
    nickname: String,
  },
  emits: ["onChangeVirtualWallet"],
  setup(props, { emit }) {
    const { t } = useI18n();
    // 获取开关状态
    const { virtual_wallet, bank_withdraw, c2c_withdraw } = UseSystemSetting();
    const state = reactive({
      safetyType: 0,
      type: 0,
      person: [
        { name: t("lever.assets"), path: "/person/myassets", show: true },
        {
          name: t("assets.recharge"),
          path: "/person/recharge",
          show: !virtual_wallet.value,
        },
        {
          name: t("assets.withdrawMoney"),
          path: "/person/cash",
          show: !virtual_wallet.value,
        },
        {
          name: t("assets.assetRecord"),
          path: "/person/AssetsRecord",
          show: !virtual_wallet.value,
        },
      ],
      person2: [
        {
          name: t("global.currentCommission"),
          path: "/person/ComminManage?active=1",
        },
        {
          name: t("global.historyCommission"),
          path: "/person/ComminManage?active=2",
        },
      ],
      dialogSafetyTip: false,
      dialogVerfiedTip: false,
    });
    watch(
      () => virtual_wallet.value,
      (val) => {
        state.person = [
          { name: t("lever.assets"), path: "/person/myassets", show: true },
          {
            name: t("assets.recharge"),
            path: "/person/recharge",
            show: !val,
          },
          {
            name: t("assets.withdrawMoney"),
            path: "/person/cash",
            show: !val,
          },
          {
            name: t("assets.assetRecord"),
            path: "/person/AssetsRecord",
            show: !val,
          },
        ];
      }
    );
    // function
    const $router = useRouter();
    const $store = useStore();
    const onVerfiedConfirm = () => {
      state.dialogVerfiedTip = false;
      $router.push("/person/Authentication");
    };
    const onVerfiedCancel = () => {
      state.dialogVerfiedTip = false;
    };

    //判断币币交易是否开启
    const isBibiShow = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "currency_transaction_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    //判断模拟账户切换是否开启
    const isVirtualShow = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = list.find((item) => {
        return item.key === "virtual_wallet";
      });
      return flag?.value || false;
    });
    //是否需要实名
    const isVerified = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "verify_switch") {
          if (
            ele.value[5] &&
            $store.state.user.securityInfo.user_auth_level !== 2
          ) {
            if ($store.state.user.securityInfo.user_auth_level !== 4) {
              state.type = 1;
            }
            flag = true;
          }
        }
      });
      return flag;
    });
    const jump = (path) => {
      if (path === "/person/cash") {
        if (isVerified.value) {
          state.dialogVerfiedTip = true;
          return false;
        }
        if (
          securityInfo.value.user_safety_certificate_status &&
          securityInfo.value.user_safety_certificate_type !== 2
        ) {
          if (securityInfo.value.user_safety_certificate_type !== 4) {
            state.safetyType = 1;
          }
          state.dialogSafetyTip = true;
          return;
        }
        if (!c2c_withdraw.value && !bank_withdraw.value) {
          path = "/person/cashPage?type=1";
        }
      }
      $router.push(path);
    };
    const securityInfo = computed(() => {
      return $store.state.user.securityInfo;
    });
    const confirm = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const viewSafeStatus = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const onCancel = () => {
      state.dialogSafetyTip = false;
    };
    const logout = () => {
      $store.dispatch("user/logout").then(() => {
        $router.push("/login");
      });
    };
    const changeVirtualWallet = () => {
      emit("onChangeVirtualWallet", !virtual_wallet.value);
    };
    return {
      state,
      logout,
      jump,
      securityInfo,
      confirm,
      onCancel,
      onVerfiedConfirm,
      onVerfiedCancel,
      isVerified,
      viewSafeStatus,
      isBibiShow,
      virtual_wallet,
      changeVirtualWallet,
      isVirtualShow,
    };
  },
};
</script>

<style lang="less" scoped>
// 个人中心弹窗
.userinfo {
  min-width: 170px;
  position: relative;
  // border-radius: 8px;
  padding: 0 10px;
}
.user {
  height: 44px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
.iconfont {
  font-size: 24px;
}
.is-rtl {
  .el-icon-arrow-right {
    transform: scaleX(-1);
    // direction: rtl;
  }
}
.name {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-start: 6px;
}
.center-model {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.mb-8 {
  margin-bottom: 8px;
}
.h_18 {
  min-height: 18px;
  line-height: 18px;
}
.logout {
  height: 37px;
  line-height: 37px;
  color: #cf2f43;
}
.break-all {
  word-break: break-all;
}
.block {
  display: block;
}
ul li {
  display: inline-block;
  width: 100%;
}
.max-content {
  width: max-content;
}
.w-72 {
  width: 72px;
}
</style>
